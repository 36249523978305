.panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-1);
  background-color: var(--color-white);
  margin-bottom: 32px;

  &--size-small {
    padding: 20px 24px;
    border-radius: var(--border-radius-small);
    margin-bottom: 16px;
  }

  &--type-ghost {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &--type-success {
    border: 2px solid var(--color-green);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;
      background-color: var(--color-green-light);
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.70725 8.22836L2.98114 5.50224L2.6983 5.2194L2.41546 5.50224L1.41247 6.50523L1.12963 6.78807L1.41247 7.07091L5.42441 11.0829L5.70725 11.3657L5.9901 11.0829L14.5871 2.48584L14.87 2.203L14.5871 1.92015L13.5841 0.91717L13.3013 0.634327L13.0184 0.91717L5.70725 8.22836Z' fill='%233D8545' stroke='%233D8545' stroke-width='0.8'/%3E%3C/svg%3E%0A");
      background-position: 50%;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      transform: translateX(-50%);
      border: 2px solid var(--color-white);
    }
  }

  &--type-warning {
    border: 2px solid var(--color-yellow);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;
      background-color: var(--color-yellow-light);
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1377_1552)'%3E%3Cpath d='M10.0002 17.5C10.9206 17.5 11.6668 16.7538 11.6668 15.8334C11.6668 14.9129 10.9206 14.1667 10.0002 14.1667C9.07969 14.1667 8.3335 14.9129 8.3335 15.8334C8.3335 16.7538 9.07969 17.5 10.0002 17.5Z' fill='%23FD9A52'/%3E%3Cpath d='M8.3335 2.5H11.6668V12.5H8.3335V2.5Z' fill='%23FD9A52'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1377_1552'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-position: 50%;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      transform: translateX(-50%);
      border: 2px solid var(--color-white);
    }
  }
}
