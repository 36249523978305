.TimeOverviewView {
  &Filter {
    background: var(--color-white);
    padding: 24px 24px 8px;
    border-bottom: 1px solid var(--color-grey-light);

    &Row {
      display: flex;
      gap: 16px;

      &Input {
        flex: 1;

        input {
          min-width: calc(100% - 16px);
          width: auto;
          padding-left: 32px !important;
          text-align: right;

          &::-webkit-calendar-picker-indicator {
            display: none;
          }

          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 14px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2.25H13.25V0.75H11.75V2.25H4.25V0.75H2.75V2.25H2C1.175 2.25 0.5 2.925 0.5 3.75V15.75C0.5 16.575 1.175 17.25 2 17.25H14C14.825 17.25 15.5 16.575 15.5 15.75V3.75C15.5 2.925 14.825 2.25 14 2.25ZM14 15.75H2V7.5H14V15.75ZM14 6H2V3.75H14V6Z' fill='%23767676'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }

  &Numbers {
    padding: 24px 24px 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--color-grey-light);
    gap: 16px;
    display: flex;
    flex-direction: column;

    &Row {
      display: flex;
      justify-content: space-between;

      &Type {
        flex: 1.5;
        display: flex;
        flex-direction: column;

        &Label {
          font-weight: 700;
        }

        &Amount {
          color: var(--color-grey);
        }
      }

      &Percentage {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      &Value {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }

  &Explanation {
    flex-grow: 1;
    min-height: calc(118px + 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Summary {
    border-top: 1px solid var(--border-color);
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: var(--default-padding);
    padding-right: var(--default-padding);
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    gap: 12px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    div {
      display: flex;
      justify-content: space-between;
      line-height: 1;
      font-weight: 500;

      &:first-child {
        font-weight: 700;
      }
    }
  }

  &NoData {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    h5 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
