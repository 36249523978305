.timeHistoryModal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &Open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .timeHistoryModalInner {
      transform: translateY(0);
    }
  }

  &Inner {
    background: var(--color-white);
    border-radius: var(--border-radius-bubble);
    box-shadow: var(--shadow-2);
    position: relative;
    width: 100%;
    max-width: 343px;
    transform: translateY(50px);
    transition: all 0.3s;
    padding: 24px;
  }

  &Entries {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    overflow-y: auto;
    max-height: 400px;
  }

  &Entry {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: var(--font-size-small);

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &Date {
      font-size: var(--font-size-xsmall);
      color: var(--color-grey);
    }
  }
}
