.timeLogInformationPanel {
  margin-left: var(--default-padding);
  margin-right: var(--default-padding);

  &Inner {
    text-align: center;
  }

  &Text {
    color: var(--color-grey);
    font-size: var(--font-size-small);
    margin-bottom: 0;
  }
}
