.timeLogHeader {
  padding-top: 32px;
  margin-left: var(--default-padding);
  margin-right: var(--default-padding);
  padding-bottom: 32px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 24px;

  &Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    margin-bottom: 0;
    line-height: 1;
  }

  &NormTime {
    color: var(--color-grey);
    line-height: 1;
  }
}
