.timeLogEntries {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
  padding-bottom: 24px;
}

.timeLogEntriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  min-height: 27px;

  &Title {
    font-size: var(--font-size-xsmall);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.22px;
  }
}

.timeLogNoEntries {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    color: var(--color-grey);
    font-size: var(--font-size-small);
    margin-bottom: 24px;
  }
}

.timeLogNoSetting {
  padding: var(--default-padding);
}
