.Menu {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  &Top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(var(--startiapp-inset-top, 24px) + 96px);
    margin-bottom: 60px;
    position: relative;

    &CloseButton {
      position: absolute;
      top: -96px;
      right: var(--default-padding);
      width: 48px;
      height: 48px;
      background: var(--color-green-lighter);
      border: 1px solid var(--color-grey-light);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &Links {
    padding-left: 24px;
    padding-right: 24px;
    flex-grow: 1;

    &Group {
      display: flex;
      flex-direction: column;

      &Header {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: var(--border-radius-bubble);
        background-color: var(--color-green-lighter);
        padding: 12px 16px;
        margin-bottom: 8px;

        h4 {
          color: var(--color-green);
          margin-bottom: 0;
        }
      }

      &Item {
        margin-left: 32px;
        background: none;
        border: 0;
        border-left: 2px solid var(--color-grey-light);
        text-align: left;
        padding: 15px 32px;
        text-decoration: none;
        color: var(--color-text);

        &Active {
          border-left-color: var(--color-green);
        }

        h5 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &Profile {
    padding-top: 20px;
    padding-bottom: 48px;
    padding-left: var(--default-padding);
    padding-right: var(--default-padding);
    border-top: 1px solid var(--color-grey-light);
    display: flex;
    align-items: center;
    gap: 8px;

    &Name {
      font-size: 14px;
      font-weight: 700;
      font-family: var(--font-family-heading);
    }

    &Company {
      font-size: 12px;
      color: var(--color-grey);
      font-weight: 500;
      font-family: var(--font-family-heading);
    }

    &Inner {
      display: flex;
      flex-direction: column;
    }
  }
}
