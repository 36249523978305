.button {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  line-height: 1;
  padding: 0px 20px 4px;
  height: 52px;
  transition: all ease-in-out 0.2s;
  font-size: var(--font-size);
  font-family: inherit;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  img {
    /* White */
    filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(5deg)
      brightness(103%) contrast(109%);
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.small {
    padding: 0 12px;
    height: 40px;
    font-size: var(--font-size-small);
  }

  &.tiny {
    padding: 0 12px 2px;
    height: 28px;
    font-size: var(--font-size-small);

    svg {
      position: relative;
      top: 1px;
    }
  }

  &.primary {
    color: var(--color-green-light);
    background-color: var(--color-green-dark);
    border-radius: var(--border-radius-bubble);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-green-darker);
        color: var(--color-green-light);
        box-shadow: var(--shadow-2);
      }
    }
  }

  &.secondary {
    color: var(--color-text);
    background-color: var(--color-white);
    border-radius: var(--border-radius-bubble);
    border: 2px solid var(--color-green-dark);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-white);
        color: var(--color-green-dark);
        box-shadow: var(--shadow-2);
      }
    }
  }

  &.text-link {
    background: none;
    color: var(--color-link);
    padding: 0;
    height: unset;

    border-radius: 0;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;

    &:hover {
      background: none;
      color: var(--color-link-hover);
    }
  }

  &.text-link--grey {
    background: none;
    color: var(--color-grey);

    padding: 0;
    height: unset;
    font-size: var(--font-size-small);

    border-radius: 0;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;

    &:hover {
      background: none;
      color: var(--color-link-hover);
    }
  }

  &.danger {
    color: var(--color-danger);
    background-color: var(--color-danger-light);
    border: 1px solid var(--color-danger);
    border-radius: var(--border-radius-bubble);

    @media (hover: hover) {
      &:hover {
        color: var(--color-white);
        background-color: var(--color-danger-dark);
        box-shadow: var(--shadow-2);
      }
    }
  }

  &.square {
    background: none;
    border: 1px solid var(--color-grey-light);
    border-radius: var(--border-radius-bubble);
    padding: 0;
    height: 40px;
    width: 40px;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-green-lighter);
      }
    }
  }

  &.rounded-green {
    background-color: var(--color-green);
    border-radius: 40px;
    color: var(--color-white);
    font-weight: 500;
  }

  &.faded {
    background-color: var(--color-green-lighter-10);
    border-radius: 40px;
    color: var(--color-white);
    font-weight: 500;
  }

  &.rounded-faded {
    background-color: var(--color-green-lighter-10);
    border-radius: 40px;
    color: var(--color-white);
    font-weight: 500;
    height: 48px;
    width: 48px;
    padding: 0;
  }
}
