:root {
  /* Colour variables */
  --color-white: #ffffff;
  --color-grey-light: #e0e0e0;
  --color-grey: #767676;
  --color-grey-dark: #414141;
  --color-black: #212121;

  --color-green-darker: #0e2e1e;
  --color-green-dark: #194a37;
  --color-green: #3d8545;
  --color-green-light: #cde4ba;
  --color-green-light-30: rgba(192, 229, 161, 0.3);
  --color-green-lighter: #f5f7f3;
  --color-green-lighter-10: rgba(245, 247, 243, 0.1);
  --color-green-lighter-50: rgba(245, 247, 243, 0.5);

  --color-red-dark: #8f2f2f;
  --color-red: #b73636;
  --color-red-light: rgba(183, 54, 54, 0.1);

  --color-yellow-dark: #b36e2d;
  --color-yellow: #fd9a52;
  --color-yellow-light: #ffebdc;
  --color-yellow-light-10: rgba(253, 154, 82, 0.1);

  --color-link: var(--color-green);
  --color-link-hover: var(--color-green-dark);
  --color-text: var(--color-black);

  /* Font variables */
  --font-family: 'Plus Jakarta Sans Variable', sans-serif;
  --font-family-heading: 'gopher', sans-serif;
  --font-size: 15px;
  --font-size-small: 13px;
  --font-size-xsmall: 11px;

  /* Layout variables */
  --border-color: var(--color-grey-light);
  --border-radius: 16px;
  --border-radius-small: 8px;
  --border-radius-xsmall: 4px;
  --border-radius-bubble: 4px 16px 16px 16px;

  --shadow-1: 0px 8px 32px 0px rgba(0, 0, 0, 0.05),
    0px 4px 16px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  --shadow-1-hover: 0px 18px 40px 0px rgba(0, 0, 0, 0.08),
    0px 14px 24px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.05);

  --shadow-2: 0px 8px 16px 0px rgba(0, 0, 0, 0.1),
    0px 4px 16px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

  --color-success-dark: var(--color-green);
  --color-success: var(--color-green-light);
  --color-danger-dark: var(--color-red-dark);
  --color-danger: var(--color-red);
  --color-danger-light: var(--color-red-light);
  --color-warning-dark: var(--color-yellow-dark);
  --color-warning: var(--color-yellow);
  --color-warning-light: var(--color-yellow-light);

  --default-padding: 16px;
}
