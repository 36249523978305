.notAuthorised {
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);

  &Top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    padding-top: calc(var(--startiapp-inset-top, 24px) + 0px);
    margin-bottom: 60px;
  }
}
