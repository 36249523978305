.timeWeekSummaryDay {
  padding: var(--default-padding);
  border-bottom: 1px solid var(--color-grey-light);
  display: flex;
  flex-direction: column;
  gap: 8px;

  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-grey);
    font-size: var(--font-size-small);

    &:first-child {
      font-size: var(--font-size);
      color: var(--color-text);
    }
  }
}
