.TimeTopCenter {
  &PreviousButton {
    display: block;
    width: 40px;
    height: 40px;
  }
  &NextButton {
    display: block;
    width: 40px;
    height: 40px;
  }

  &Current {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    min-width: 100px;
    color: var(--color-white);
    text-decoration: none;

    &Title {
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 1;
      white-space: nowrap;
      text-transform: capitalize;
    }

    &Text {
      position: absolute;
      bottom: -20px;
      opacity: 0.5;
      font-size: var(--font-size-xsmall);
      white-space: nowrap;
    }
  }
}
