.inputField {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;

  &:not(.inputFieldCheckbox, .inputFieldSwitch) {
    label {
      position: absolute;
      top: 15px;
      left: 17px;
      font-size: var(--font-size);
      color: var(--color-grey);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
    }
  }

  input:not([type='checkbox']) {
    font-size: var(--font-size);
    color: var(--color-text);
    background-color: var(--color-white);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-bubble);
    padding: 24px 16px 9px;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    font-family: var(--font-family);

    &::placeholder {
      color: var(--color-grey);
      opacity: 0;
    }

    &::-webkit-calendar-picker-indicator {
      position: relative;
      top: -6px;
    }

    &:focus {
      border-color: var(--color-green);
    }

    &:not(:placeholder-shown),
    &:focus {
      + label {
        position: absolute;
        top: 9px;
        left: 17px;
        font-size: var(--font-size-xsmall);
        color: var(--color-grey);
        opacity: 1;
      }
    }

    @media (hover: hover) {
      &:hover:not(:focus) {
        background-color: var(--color-green-lighter-50);
      }
    }

    &:disabled {
      background-color: var(--color-grey-light);
      color: var(--color-grey);
      pointer-events: none;
      user-select: none;
      cursor: not-allowed;
    }
  }

  &Error {
    input {
      border-color: var(--color-danger);
      background-color: var(--color-danger-light);

      &:focus {
        border-color: var(--color-danger);
      }
    }
  }

  .error {
    margin-top: 4px;
    color: var(--color-danger);
    font-size: var(--font-size-xsmall);
  }

  .help {
    margin-top: 4px;
    color: var(--color-grey);
    font-size: var(--font-size-xsmall);
  }

  &Switch {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      position: relative;
    }

    input {
      opacity: 0; // hides checkbox
      position: absolute;
      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        -moz-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        -webkit-tap-highlight-color: transparent;
        height: 30px;
        width: 50px;
        border: 1px solid #e4e4e4;
        border-radius: 60px;
        &:before {
          content: '';
          position: absolute;
          display: block;
          background: var(--color-grey);
          transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
          height: 30px;
          width: 51px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
          box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
            0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
            0 3px 3px hsla(0, 0%, 0%, 0.05);

          background: var(--color-white);
          height: 28px;
          width: 28px;
          top: 1px;
          left: 0px;
          border-radius: 60px;
        }
      }
      // When Active
      &:checked {
        & + label:before {
          background: var(--color-green); // Active Color
          -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
          -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
          -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
          transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
        }
        & + label:after {
          left: 54px - 30px;
        }
      }
    }
  }

  &Checkbox {
    display: flex;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      user-select: none;
    }

    input {
      appearance: none;
      -webkit-appearance: none;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 0;
      width: 24px;
      height: 24px;
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-xsmall);
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        transform: scale(0);
        transition: transform 0.2s ease-in-out;
        background-image: url('data:image/svg+xml;utf8,<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.60109 8.86L1.24109 5.5L0.121094 6.62L4.60109 11.1L14.2011 1.5L13.0811 0.380005L4.60109 8.86Z" fill="rgb(61, 133, 69)"/></svg>');
      }

      &:checked {
        border-color: var(--color-green);

        &:before {
          transform: scale(1);
        }
      }

      &:hover {
        background: var(--color-green-light-30);
      }
    }
  }

  &Select {
    position: relative;

    select {
      font-size: var(--font-size);
      font-weight: 500;
      color: var(--color-text);
      background-color: var(--color-white);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-bubble);
      padding: 24px 48px 9px 16px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="rgb(61, 133, 69)"/></svg>');
      background-repeat: no-repeat;
      background-position: right 16px center;
      background-size: 24px;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;

      &:focus {
        border-color: var(--color-green);
      }

      &:disabled {
        background-color: var(--color-grey-light);
        color: var(--color-grey);
        pointer-events: none;
        user-select: none;
        cursor: not-allowed;
      }

      &:valid,
      &:invalid {
        + label {
          position: absolute;
          top: 9px;
          left: 17px;
          font-size: var(--font-size-xsmall);
          color: var(--color-grey);
          opacity: 1;
        }
      }
    }
  }

  &Textarea {
    textarea {
      font-size: var(--font-size);
      color: var(--color-text);
      background-color: var(--color-white);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-bubble);
      padding: 24px 16px 9px;
      transition: all 0.2s ease-in-out;
      min-height: 120px;
      cursor: pointer;
      outline: none;
      font-family: var(--font-family);
      resize: none;

      &::placeholder {
        color: var(--color-grey);
        opacity: 0;
      }

      &:focus {
        border-color: var(--color-green);
      }

      &:not(:placeholder-shown),
      &:focus {
        + label {
          position: absolute;
          top: 9px;
          left: 17px;
          font-size: var(--font-size-xsmall);
          color: var(--color-grey);
          opacity: 1;
        }
      }

      @media (hover: hover) {
        &:hover:not(:focus) {
          background-color: var(--color-green-lighter-50);
        }
      }

      &:disabled {
        background-color: var(--color-grey-light);
        color: var(--color-grey);
        pointer-events: none;
        user-select: none;
        cursor: not-allowed;
      }
    }
  }

  &VisibleLabel {
    label {
      position: static !important;
      order: -1;
      margin-bottom: 4px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: var(--font-size-xsmall) !important;
    }

    input:not([type='checkbox']) {
      height: 54px;
      padding: 0 12px;
      line-height: 1;

      &::-webkit-calendar-picker-indicator {
        order: -1;
        position: relative;
        top: 0;
        left: -8px;
        opacity: 0.5;
      }
    }

    select {
      padding-top: 16px;
      padding-bottom: 17px;
    }

    textarea::placeholder {
      opacity: 1 !important;
    }
  }
}
