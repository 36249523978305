.TimeLogDays {
  display: flex;
  background: var(--color-green-dark);

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-white);
    text-decoration: none;
    background: var(--color-green-dark);
    flex: 1;
    padding: 12px 0 8px;
    border-radius: 10px 10px 0 0;

    div {
      opacity: 0.75;
    }

    &Day {
      font-size: 12px;
      text-transform: capitalize;
    }
    &Date {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    &Month {
      font-size: 12px;
    }
  }

  &Selected {
    background: var(--color-green-lighter);
    color: var(--color-green-dark);
  }

  &Today {
    position: relative;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      background: var(--color-yellow);
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
    }
  }

  &Locked {
    position: relative;

    &:not(.TimeLogDaysSelected) {
      div,
      h4 {
        opacity: 0.25;
      }
    }

    &:after {
      content: '';
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background-color: var(--color-green-light);
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.56703 5.39272L1.86321 3.6889L1.68644 3.51212L1.50966 3.6889L0.882794 4.31576L0.706017 4.49254L0.882794 4.66931L3.39026 7.17678L3.56703 7.35355L3.74381 7.17678L9.11695 1.80364L9.29372 1.62687L9.11695 1.45009L8.49008 0.823223L8.3133 0.646447L8.13653 0.823223L3.56703 5.39272Z' fill='%233D8545' stroke='%233D8545' stroke-width='0.5'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }
  }
}
