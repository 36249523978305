.timeApproveEntry {
  display: block;
  color: var(--color-text);
  text-decoration: none;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-light);
  padding: 24px;

  &Label {
    font-size: var(--font-size-small);
    color: var(--color-green);
    margin-bottom: 2px;
  }

  &Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Title {
    font-weight: 700;
  }
}
