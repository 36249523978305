.timeLogSummarySpacer {
  min-height: 118px;
  flex-grow: 1;
}

.timeLogSummary {
  border-top: 1px solid var(--border-color);
  padding-top: 24px;
  padding-bottom: 48px;
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  gap: 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  div {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    font-weight: 500;

    &:last-child {
      font-weight: 700;
    }
  }
}
