.Infobox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &Backdrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    pointer-events: none;
  }

  &Trigger {
    margin-top: 8px;
    text-decoration: underline;
    cursor: pointer;
  }

  &Content {
    margin: 0 24px;
    position: absolute;
    background-color: var(--color-white);
    padding: 24px;
    border: 1px solid var(--color-grey-light);
    box-shadow: var(--shadow-2);
    border-radius: var(--border-radius-bubble);
    display: flex;
    flex-direction: column;
    z-index: 1002;
    text-align: left;

    p {
      font-size: var(--font-size);
      color: var(--color-text);
      margin-bottom: 24px;
    }

    *:last-child {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
