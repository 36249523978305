.preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;

  &__inner {
    display: flex;
    gap: 0.2rem;
  }

  &__leaf {
    &__1 {
      width: 50px;
      height: 50px;
      background-color: var(--color-green-dark);
      border-radius: 100rem 100rem 20rem 100rem;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: preloadLeaf1;
    }
    &__2 {
      width: 35px;
      height: 35px;
      background-color: var(--color-green-light);
      border-radius: 20rem 100rem 100rem 100rem;
      margin-top: 30px;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: preloadLeaf1;
    }
  }
}

@keyframes preloadLeaf1 {
  0% {
    width: 50px;
    height: 50px;
  }
  50% {
    width: 35px;
    height: 35px;
  }
  100% {
    width: 50px;
    height: 50px;
  }
}

@keyframes preloadLeaf2 {
  0% {
    width: 35px;
    height: 35px;
  }
  50% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 35px;
    height: 35px;
  }
}
