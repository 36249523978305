.timeApproveEntryView {
  &Actions {
    margin: 0 16px 32px;

    &Feedback {
      font-size: var(--font-size-small);
      color: var(--color-danger);
      margin-bottom: 16px;
    }
  }
}
