* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-green-lighter);
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: 1.5;
  font-weight: 500;
  min-height: 100vh;

  color: var(--color-text);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    &-track {
      background: var(--color-grey-light);
    }

    &-thumb {
      background: var(--color-grey);
      border-radius: 4px;
    }
  }
}

a {
  color: var(--color-link);
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 6px;
  transition: color 0.2s ease-in-out;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
