.TopBar {
  background: var(--color-green-dark);
  color: var(--color-white);
  padding-top: calc(var(--startiapp-inset-top, 24px) + 0px);
  padding-bottom: 24px;
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Left {
    margin-right: auto;
    flex: 1;
  }

  &Center {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &Right {
    margin-left: auto;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
