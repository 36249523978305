.ModalBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  pointer-events: none;
}

.Modal {
  border-radius: 24px 24px 0 0;
  background: var(--color-green-lighter);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1002;
  width: 100%;
  height: 90%;

  &Header {
    background: var(--color-white);
    border-radius: 24px 24px 0 0;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05),
      0px 4px 16px rgba(0, 0, 0, 0.05), 0px 8px 32px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 27px 0;
    position: relative;

    h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    &Close {
      position: absolute;
      right: 8px;
      background: none;
      border: 0;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      transform: translateY(16px);

      svg {
        flex-shrink: 0;
      }
    }
  }

  &Content {
    padding: 0 24px;
    overflow-y: auto;
    height: 100%;
  }

  &Footer {
    padding: 24px;
  }

  &DateSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-grey-light);
    padding-bottom: 40px;
    margin-bottom: 24px;
    min-height: 82px;

    &PreviousButton,
    &NextButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: none;
      border: none;

      &:disabled {
        opacity: 0.5;
      }

      svg {
        flex-shrink: 0;
      }
    }

    &Current {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      &Date {
        margin-top: 3px;
        margin-bottom: 0;
        line-height: 1;
        white-space: nowrap;
        padding-left: var(--default-padding);
        padding-right: var(--default-padding);
      }

      &DateWeekDay {
        position: absolute;
        bottom: -24px;
        opacity: 0.5;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }
  }

  &Fields {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Row {
      display: flex;
      gap: 16px;
    }

    &HelpText {
      font-size: var(--font-size-small);
      color: var(--color-grey);
      margin-bottom: 16px;
    }

    &Feedback {
      font-size: var(--font-size-small);
      color: var(--color-danger);
      margin-bottom: 16px;
    }

    &TimeInput,
    &DateInput {
      flex: 1;
      margin-bottom: 0;

      input {
        min-width: calc(100% - 16px);
        width: auto;
        text-align: center;
        padding-left: 32px !important;

        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }

    &TimeInput {
      input {
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          left: 14px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.9915 0.666626C4.3915 0.666626 0.666504 4.39996 0.666504 8.99996C0.666504 13.6 4.3915 17.3333 8.9915 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99996C17.3332 4.39996 13.5998 0.666626 8.9915 0.666626ZM8.99984 15.6666C5.3165 15.6666 2.33317 12.6833 2.33317 8.99996C2.33317 5.31663 5.3165 2.33329 8.99984 2.33329C12.6832 2.33329 15.6665 5.31663 15.6665 8.99996C15.6665 12.6833 12.6832 15.6666 8.99984 15.6666ZM9.4165 4.83329H8.1665V9.83329L12.5415 12.4583L13.1665 11.4333L9.4165 9.20829V4.83329Z' fill='%23767676'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &DateInput {
      input {
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          left: 14px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2.25H13.25V0.75H11.75V2.25H4.25V0.75H2.75V2.25H2C1.175 2.25 0.5 2.925 0.5 3.75V15.75C0.5 16.575 1.175 17.25 2 17.25H14C14.825 17.25 15.5 16.575 15.5 15.75V3.75C15.5 2.925 14.825 2.25 14 2.25ZM14 15.75H2V7.5H14V15.75ZM14 6H2V3.75H14V6Z' fill='%23767676'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &HoursInput {
      width: 71px;
      margin-bottom: 0;

      input {
        text-align: center;
      }
    }

    &DaysInput {
      margin-bottom: 0;

      input {
        text-align: center;
      }
    }
  }

  &DeleteButtonWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
