.Tabs {
  display: flex;
  background: var(--color-green-dark);
}

.Tab {
  flex: 1;
  padding: 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  text-decoration: none;
}

.Active {
  background-color: var(--color-white);
  color: var(--color-green-dark);
}
