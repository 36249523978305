h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-family-heading);
  margin-top: 0;
  font-weight: 700;
  line-height: 1.2;

  + p {
    margin-top: 0;
  }
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5 {
  margin-top: 40px;
}

h1 {
  font-size: 48px;
  margin-bottom: 40px;
}

h2 {
  font-size: 36px;
  margin-bottom: 32px;
}

h3 {
  font-size: 28px;
  margin-bottom: 24px;
}

h4 {
  font-size: 20px;
  margin-bottom: 16px;
}

h5 {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}
