.timeApproveEmptyState {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  height: 100%;
  text-align: center;
}
