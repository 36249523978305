.TimeSummary {
  padding: 24px 24px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--color-grey-light);
  gap: 16px;
  display: flex;
  flex-direction: column;

  &Row {
    display: flex;
    justify-content: space-between;

    &Type {
      flex: 2.5;
      display: flex;
      flex-direction: column;

      &Label {
        font-weight: 700;
      }

      &Amount {
        color: var(--color-grey);
      }
    }

    &Percentage {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &Value {
      flex: 1.5;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  &Total {
    border-top: 1px solid var(--border-color);
    padding-top: 24px;
    padding-bottom: 40px;
    padding-left: var(--default-padding);
    padding-right: var(--default-padding);
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    gap: 12px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    div {
      display: flex;
      justify-content: space-between;
      line-height: 1;
      font-weight: 500;

      &:first-child {
        font-weight: 700;
      }
    }
  }

  &NoData {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    h5 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &MiniFeed {
    background: var(--color-white);
    padding-bottom: 24px;
    gap: 12px;

    .TimeSummaryRow {
      font-size: var(--font-size-small);
      color: var(--color-grey);

      &Type {
        &Label {
          font-weight: 400;
        }
      }

      &Total {
        font-size: var(--font-size);
        color: var(--color-text);

        .TimeSummaryRowTypeLabel,
        .TimeSummaryRowValue {
          font-weight: 700;
        }
      }
    }
  }
}
