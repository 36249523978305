.Avatar {
  background-size: cover;
  background-color: var(--color-grey-light);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  font-family: var(--font-family-heading);
  line-height: 1;

  &.large {
    width: 80px;
    height: 80px;
    font-size: 24px;
  }

  &.small {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
}
