.timeLogEntry {
  margin-bottom: 24px;

  &Type {
    font-weight: 700;
    line-height: 1;
    display: block;
    margin-bottom: 12px;
  }

  &Inner {
    display: flex;
    justify-content: space-between;

    &Time {
      color: var(--color-grey);
      line-height: 1;
      display: block;
    }

    &Hours {
      line-height: 1;
      display: block;
      color: var(--color-success-dark);

      &Negative {
        color: var(--color-danger);
      }

      &Neutral {
        color: var(--color-text);
      }
    }
  }

  &Note {
    display: block;
    margin-top: 18px;
    font-size: var(--font-size-small);
    color: var(--color-grey);
  }
}
